import React from 'react';

import AlbumModal from './AlbumModal';

import { getLink } from '../../../../services/gogHelper';
import PgWrapper from './PgWrapper';

export default class Gog extends React.Component<GogProps, GogState> {
  constructor(props: any) {
    super(props);

    this.state = {
      isModalOpen: false,
      currentLink: '',
    };
    this.onAlbumClick = this.onAlbumClick.bind(this);
  }

  onAlbumClick(album: GogAlbum) {
    const link = album && getLink(album);
    if (link) {
      const albumClick = this.props.styleParams.numbers.albumClick;

      if (
        albumClick === 1 &&
        !this.props.mobile &&
        screen &&
        screen.width >= 1200
      ) {
        this.setState({
          currentLink: link,
          isModalOpen: true,
        });
      } else {
        window.open(link);
      }
    }
  }


  render() {
    return (
      <>
        <PgWrapper
          galleryItems={this.props.albumsCovers}
          dimensions={this.props.dimensions}
          styleParams={this.props.styleParams}
          textPresets={this.props.textPresets}
          coverIdToAlbum={this.props.coverIdToAlbum}
          onAlbumClick={this.onAlbumClick}
          isMobile={this.props.mobile}
          Wix={this.props.Wix}
          viewMode={this.props.viewMode}
          updateLayout={this.props.updateLayout}
          compId={this.props.compId}
          setHeight={this.props.setHeight}
          t={this.props.t}
          formFactor={this.props.formFactor}
        />
        <AlbumModal
          closeModal={() => this.setState({ isModalOpen: false })}
          isOpen={this.state.isModalOpen}
          link={this.state.currentLink}
        />
      </>
    );
  }
}
